<template>
  <div class="assets-type-tree content-block">
    <fm-title title-text="固定资产分类"></fm-title>
    <div class="search-c" v-if="isSelect">
      <fm-radio-group v-model="sParm" name="fruit">
        <fm-radio value="all" label="全部"></fm-radio>
        <fm-radio value="3220000" label="医疗设备"></fm-radio>
      </fm-radio-group>
    </div>
    <div class="assets-type-tree-c">
      <fm-tree v-loadingx="loading" :nodes="showNodes" :node-render="nodeRender" search is-selected></fm-tree>
    </div>
  </div>
</template>

<script>
import AssetsTypeLabel from './assetsTypeLabel'

const findData = (code, datas) => {
  let find = null
  let i = 0
  while (!find && i < datas.length) {
    let item = datas[i]
    if (item.data.code === code) {
      find = item.children
    } else if (item.children && item.children.length > 0) {
      find = findData(code, item.children)
    }
    i += 1
  }
  return find
}

export default {
  props: {
    isSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      sParm: this.isSelect ? '3220000' : 'all'
    }
  },
  computed: {
    nodes () {
      let fn = (data) => {
        return {
          title: data.code + ' ' + data.name,
          data: data
        }
      }
      let result = this.$store.getters.assetsTypeList.map(fn)
      let each = (nodes) => {
        nodes.forEach(node => {
          node.children = node.data.child.map(fn)
          each(node.children)
        })
      }
      each(result)
      return result
    },
    showNodes () {
      let data = this.nodes
      if (this.sParm !== 'all') {
        data = findData(this.sParm, data)
      }
      return data
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    change (data) {
      this.$emit('change', data)
    },
    nodeRender (h, data) {
      return h(AssetsTypeLabel, {
        props: {
          data: data,
          isSelect: this.isSelect
        },
        on: {
          change: (dataItem) => {
            this.change(dataItem)
          }
        }
      })
    },
    // onSelected (node) {
    //   console.log(node)
    // },
    async loadData () {
      this.loading = true
      await this.$store.dispatch('loadAssetsTypeList')
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.assets-type-tree {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
}
.search-c {
  position: absolute;
  top: 12px;
  left: 127px;
}
.assets-type-tree-c {
  margin: 1rem;
  height: calc(100% - 48px - 2rem); 
  overflow: auto;
}
</style>